import * as React from "react"
import Layout from "../components/layout"
import CRMSolution from "../components/Solutions/CRMSolution"






const crm = () => {
  return (
  <Layout>
   <CRMSolution/>
  </Layout>
)
};
export default crm;
