import React from "react"
import { Link } from "gatsby"
import imagescrm from "../../assets/images/banners/solutions-images/crm.png"

import strategy from "../../assets/images/banners/icon-pack/roadmap.png"
import collaboration from "../../assets/images/banners/icon-pack/deal.png"
import operation from "../../assets/images/banners/icon-pack/cogwheel.png"
import analytics from "../../assets/images/banners/icon-pack/analytics.png"



import "../../assets/css/style.css"
import "../../assets/css/custom.css"
// import "../../assets/js/vendor/bootstrapp.min.js"
import "../../assets/css/vendor/vendor.min.css"
import "../../assets/css/plugins/plugins.min.css"

const CRMSolution = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div className="about-banner-wrap banner-space bg-img crm" data-bg="">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">CRM Solutions</h1>
                  <h5 className="font-weight--normal text-white">
                    Aamanto’s Customer Relationship Management (CRM) is a tool
                    for managing customer relationships. In today's world,
                    consumers are a company's most precious asset and must be
                    prioritized above all.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--=========== About Company Area Start ==========-->
             <!--=========== Service Projects Wrapper Start =============--> */}
        <div className="service-projects-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">Our IT Solution</h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-xl-6 order-2 order-lg-1 v1-d-f">
                <div className="v1-dt-box-0-text">
                  <h2 className="heading">CRM <span className="text-color-primary">Solutions</span></h2>
                  It brings together the support team, the sales
                  department, the production team, and service users and
                  consumers. This ensures that the company provides
                  better support and added features throughout the
                  business lifecycle, lowering costs and improving
                  client loyalty. If you're looking to adopt a CRM
                  solution. Aamanto has a qualified CRM development team
                  that can painstakingly assess your business demands
                  and develop a solution that completely fits your
                  business aims.
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 order-1 order-lg-2">
                <div className="v1-dt-box-0-img">
                  <img className="img-fluid" src={imagescrm} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--=========== Service Projects Wrapper End =============-->

           <!--===========  feature-large-images-wrapper  Start =============--> */}
        <div className="feature-large-images-wrapper section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <!-- section-title-wrap Start --> */}
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">Result-Driven <span className="text-color-primary">Customer Relationship Management Solutions</span></h2>
                </div>
                {/* <!-- section-title-wrap Start --> */}
              </div>
            </div>

            {/* <div className="row">
              <div className="col-12">
                <div className="feature-images__six">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={quality} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Strategic CRM</h5>
                            <div className="text">
                              Strategic CRM is the answer for firms that
                              prioritize and value customers above anything
                              else. It collects, classifies, and applies client
                              information seamlessly and analyzes market trends
                              to deliver the finest solutions.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src={security}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Collaborative CRM</h5>
                            <div className="text">
                              As smoothly as possible. It combines data about
                              external interactions and gives all relevant
                              information to all parties concerned. Instant
                              messaging, EDI, and other communication tools and
                              technologies are included.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={speed} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Operational CRM</h5>
                            <div className="text">
                              Client-centric processes emphasize operational
                              CRM, in which all data and information about a
                              single client are consolidated into a single file.
                              You can provide better services if you know the
                              client's purchasing history
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src={security}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Analytical CRM</h5>
                            <div className="text">
                              Marketing and sales strategies for increasing
                              future sales. Data mining and analysis can
                              discover the latest market tendencies from a
                              broader perspective and analytics.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div class="v1-box-2-parent">
              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={strategy} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Strategic CRM</h5>
                            <div className="v1-text-2">
                              Strategic CRM is the answer for firms that prioritize and value customers above anything else. It collects, classifies, and applies client information seamlessly and analyzes market trends to deliver the finest solutions.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={collaboration} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Collaborative CRM</h5>
                            <div className="v1-text-2">
                              As smoothly as possible. It combines data about external interactions and gives all relevant information to all parties concerned. Instant messaging, EDI, and other communication tools and technologies are included.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={operation} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Operational CRM</h5>
                            <div className="v1-text-2">
                              Client-centric processes emphasize operational CRM, in which all data and information about a single client are consolidated into a single file. You can provide better services if you know the client's purchasing history
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={analytics} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Analytical CRM</h5>
                            <div className="v1-text-2">
                              Marketing and sales strategies for increasing future sales. Data mining and analysis can discover the latest market tendencies from a broader perspective and analytics.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

            </div>

          </div>
        </div>
        {/* <!--===========  feature-large-images-wrapper  End =============-->
            <!--=========== About Company Area End ==========-->
            
            <!--========= More Services Start ===========--> */}
        <div
          className="about-delivering-wrapper section-space--pb_80"
          style={{ display: "none" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h1 className="heading">
                    We Excel In Delivering <br />
                    <span className="text-color-primary">
                      {" "}
                      Optimal CRM Solution.
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> CRM Software Development </h5>
                      <div className="desc">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> CRM Mobile Application </h5>
                      <div className="desc">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Implementation of CRM </h5>
                      <div className="desc">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">CRM Integration Service </h5>
                      <div className="desc">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">CRM Maintenance & Support </h5>
                      <div className="desc">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">CRM Maintenance & Support </h5>
                      <div className="desc">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">CRM Consulting Services</h5>
                      <div className="desc">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">CRM Consulting Services </h5>
                      <div className="desc">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">CRM Extensions </h5>
                      <div className="desc">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--========= More Services End ===========--> */}
      </div>
    </div>
  )
}

export default CRMSolution
